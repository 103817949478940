import { getTheme, pxToRem, styled } from '~/utils';

// Spacing
const smallSpacing = getTheme('smallSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const largeSpacing = getTheme('largeSpacing');

// Radius
const smallRadius = getTheme('smallRadius');


// Breakpoints
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const IconContainer = styled.div<{ isOpened: boolean }>`
  display: flex;
  align-self: flex-end;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: ${({ isOpened }) => (isOpened ? 'rotate(-180deg)' : 'rotate(0deg)')};
`

export const Content = styled.div`
  width: 100%;
  min-width: ${pxToRem(200)};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const DropDownTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  color: white;
  font-size: ${pxToRem(14)};

  @media ${inMobileAndTablet} {
    font-size: ${pxToRem(12)};
  }
`;

export const DropDownHeader = styled.div`
  cursor: pointer;
  flex: 1;
  background-color: #183E62;
  border-radius: ${smallRadius};
  padding: ${smallSpacing};
  margin: 16px ${largeSpacing} 0 ${largeSpacing};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #2F5E7A;
  }
`;

export const Text = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${pxToRem(300)};
  
  @media (min-width: 1601px) and (max-width: 1800px) {
    max-width: ${pxToRem(250)};
  }

  @media (min-width: 1301px) and (max-width: 1600px) {
    max-width: ${pxToRem(180)};
  }

  @media (max-width: 1300px) {
    max-width: ${pxToRem(150)};
  }
`;