import React, { FC, useState } from 'react';
import { Expand, Jira } from '~/assets/svg';
import { AddProject } from '~/components';
import { StyledMenu, StyledMenuItem } from '~/styles';
import {
  Content,
  DropDownHeader,
  DropDownTitle,
  IconContainer,
  Text
} from './styles';
import { observer, useStores } from '~/utils';
import SearchBar from '../SearchBar';

type Props = {
  type: 'project' | 'epic';
  onClick: () => void;
};

const DropDown: FC<Props> = ({ type, onClick }) => {
  const { epic, project } = useStores();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [searchInput, setSearchInput] = useState('');
  const filteredProjects = project.items.filter((item) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );
  const filteredEpics = epic.items
  .filter((item) =>
    project.selected ? item.project.id === project.selected.id : false
  )
  .filter((item) => item.feedback === 2)
  .filter((item) =>
    item.content.summary.toLowerCase().includes(searchInput.toLowerCase())
  );
  return (
    <>
      <Content>
        <DropDownHeader
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            setAnchorEl(event.currentTarget as unknown as HTMLButtonElement)
          }
        >
          <DropDownTitle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {type === 'project' &&
                (project.selected ? (
                  <>
                    {project.selected.type === 'jira' && (
                      <Jira
                        color="#fff"
                        size={16}
                        style={{ marginRight: '8px' }}
                      />
                    )}
                    <Text>{project.selected.name}</Text>
                  </>
                ) : (
                  'Selecione um projeto'
                ))}
              {type === 'epic' &&
                (epic.selected
                  ? epic.selected.content.summary
                  : 'Selecione um épico')}
            </div>
          </DropDownTitle>
          <IconContainer isOpened={Boolean(anchorEl)}>
            <Expand />
          </IconContainer>
        </DropDownHeader>
      </Content>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : 'auto',
            minWidth: '100px',
          },
        }}
      >
        {type === 'project' && (
          <>
            <SearchBar
              placeholder='Buscar projeto'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
            {filteredProjects.map((item, index) => (
              <StyledMenuItem
                key={index}
                onClick={() => {
                  project.select(item.id);
                  onClick();
                  setAnchorEl(null);
                }}
                selected={project.selected?.id === item.id}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {item.type === 'jira' && (
                    <Jira
                      color="#fff"
                      size={16}
                      style={{ marginRight: '8px' }}
                    />
                  )}
                  <Text>
                    {item.name}
                  </Text>
                </div>
              </StyledMenuItem>
            ))}
            <AddProject setAnchorEl={setAnchorEl} />
          </>
        )}
        {type === 'epic' && (
          <>
            <SearchBar
              placeholder='Buscar épico'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
            {filteredEpics.map((item, index) => (
              <StyledMenuItem
                key={index}
                onClick={() => {
                  epic.select(item.id);
                  onClick();
                  setAnchorEl(null);
                }}
                selected={epic.selected?.id === item.id}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text>
                    {item.content.summary}
                  </Text>
                </div>
              </StyledMenuItem>
            ))}
          </>
        )}
      </StyledMenu>
    </>
  );
};

export default observer(DropDown);